import FloatingNew from "@/components/floatingNew";
import DataBox from "@/components/generic/dataBox";
import Header from "@/components/generic/header";
import Layout from "@/components/layout";
import TicketBoxLink from "@/components/ticketBoxLink";
import { useGetTicketsQuery } from "@/generated";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import MuiLink from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Link, PageProps } from "gatsby";
import React, { useEffect, useState } from "react";

const BerichtenPage: React.FC<PageProps> = () => {
  const take = 5;
  const [skip, setSkip] = useState(0);
  const { data, loading, error, fetchMore } = useGetTicketsQuery({
    variables: { take, skip },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (skip > 0) {
      (async () => {
        await fetchMore({
          variables: {
            skip,
          },
        });
      })();
    }
  }, [skip]);

  if (error) {
    return (
      <Layout title="Helaas">
        <Container maxWidth="md">
          <Header>Berichten centrum</Header>
          <DataBox loading={false} header="Helaas">
            <Typography align="center">Er is iets mis gegaan bij het laden van de berichten.</Typography>
            <Typography align="center">
              Probeer het op een later moment nog eens. Als de foutmelding blijft neem dan{" "}
              <MuiLink component={Link} to="/support/">
                contact
              </MuiLink>{" "}
              op.
            </Typography>
          </DataBox>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout title="Mijn Berichten">
      <Container maxWidth="md" sx={{ position: "relative" }}>
        <Header>Berichten centrum</Header>
        {!data?.authenticatedItem?.tickets || data?.authenticatedItem?.tickets.length === 0 ? (
          <>
            <Typography align="center">Er zijn nog geen berichten.</Typography>
            <Typography align="center">Klik op de knop rechtsboven om een nieuw bericht te sturen.</Typography>
          </>
        ) : (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 6 }}>
            {data.authenticatedItem.tickets.map((ticket) => (
              <TicketBoxLink key={ticket.id} ticket={ticket} loading={false} />
            ))}
            {data.authenticatedItem.tickets.length < data.authenticatedItem.ticketsCount && (
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <LoadingButton
                  loading={loading}
                  variant="text"
                  color="tertiary"
                  onClick={(e) => {
                    e.preventDefault();
                    setSkip((s) => s + take);
                  }}
                >
                  Laad meer...
                </LoadingButton>
              </Box>
            )}
          </Box>
        )}
        <FloatingNew sx={{ top: { xs: -52, md: 12 } }} />
      </Container>
    </Layout>
  );
};

export default BerichtenPage;
